import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import ContactForm from '../../components/contact-form'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PortfolioFeed from '../../components/portfolio-feed'
import Quote from '../../components/quote'
import Communicate from '../../icons/communicate.svg'
import Everything from '../../icons/everything.svg'
import MarketResearch from '../../icons/market-research.svg'
import Personas from '../../icons/personas.svg'
import Strategy from '../../icons/strategy.svg'

const PortfolioHeader = () => (
  <section className='bg-gray-700 py-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='text-secondary-light'>Client</h3>
              <p className='text-white'>729 Solutions (Internal Project)</p>
            </div>
            <div className='col-12 mt-0 mt-lg-5'>
              <h3 className='text-secondary-light'>Our Role</h3>
              <p className='text-white'>UX/UI Design</p>
              <p className='text-white'>Graphic Design</p>
              <p className='text-white'>Website Design</p>
              <p className='text-white'>Logo Design</p>
              <p className='text-white'>Identity Design</p>
              <p className='text-white'>Branding</p>
              <p className='text-white'>Marketing Materials</p>
              <p className='text-white'>Monitoring and Maintenance</p>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-8 mt-5 mt-lg-0'>
          <h3 className='text-secondary-light'>Summary</h3>
          <h2 className='text-white mb-0'>
            The 729 Solutions Brand Guide became an integral, time saving resource for tools and assets, helping to align our team and partners on how to use
            our brand assets.
          </h2>
        </div>
      </div>
    </div>
  </section>
)

const MockupImages = () => (
  <section>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <StaticImage src='../../images/portfolio/729/brand-guide-hero.jpg' alt='729 Brand guide Mockups' />
        </div>
      </div>
    </div>
  </section>
)

const ProjectGoals = () => (
  <section className='bg-light py-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='row'>
            <div className='col-12'>
              <h2>Problem Statement</h2>
              <p>
                Individual team members used several different locations to store our brand assets, templates, etc. From cloud storage solutions to local hard
                drives, it was hard to make sure we are all using the same versions of our logos, badges, colors, and more.
              </p>
              <p>
                Without a complete asset and component library our team members were forced to create their own assets which resulted in a less cohesive look
                and feel for our brand.
              </p>
              <p>
                In addition, we needed a central location to store the latest versions of our ever-evolving collection of collateral templates, website and
                UI/UX design templates, and component libraries.
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6 mt-5 mt-lg-0'>
          <div className='row'>
            <div className='col-12'>
              <h2>Project Goals</h2>
              <ol className='text-primary'>
                <li>
                  <span className='text-dark'>Refine the 729 Solutions’ brand.</span>
                </li>
                <li>
                  <span className='text-dark'>Create a central platform to store our brand assets.</span>
                </li>
                <li>
                  <span className='text-dark'>Provide tools and assets to align on use for the brand.</span>
                </li>
                <li>
                  <span className='text-dark'>Describe for less seasoned users, how to use the brand elements.</span>
                </li>
                <li>
                  <span className='text-dark'>Provide tools and assets to align designer thinking.</span>
                </li>
                <li>
                  <span className='text-dark'>Create a helpful resource for team members and partners.</span>
                </li>
                <li>
                  <span className='text-dark'>Save time (aka money!) </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const HowWeDidIt = () => (
  <section className='my-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='mb-2'>Software Used</h2>
              <div className='row'>
                <div className='col-9'>
                  <div className='row mb-4'>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/adobe-xd.png' alt='Adobe XD' width={50} height={50} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/adobe-id.png' alt='Adobe Id' width={50} height={50} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/adobe-illustrator.png' alt='Adobe Illustrator' width={50} height={50} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/adobe-photoshop.png' alt='Adobe Photoshop' width={50} height={50} />
                    </div>
                  </div>
                  <div className='row align-items-center'>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/wordpress.png' alt='Wordpress' width={50} height={50} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/divi.png' alt='Divi' width={50} height={50} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/gsheets.png' alt='Google Sheets' width={50} height={60} />
                    </div>
                    <div className='col-2 pr-0'>
                      <StaticImage src='../../images/tools/gdrive.png' alt='Google Drive' width={60} height={50} />
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-12'>
                      <small>
                        We use (and love!) the{' '}
                        <a href='https://www.elegantthemes.com/affiliates/idevaffiliate.php?id=45947'>
                          <StaticImage src='../../images/tools/divi.png' width={20} height={20} alt='Divi' /> Divi theme.
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <h2 className='mt-5 mb-2'>Development Languages Used</h2>
              <p className='mb-0'>PHP</p>
              <p className='mb-0'>JavaScript</p>
              <p className='mb-0'>HTML 5</p>
              <p className='mb-0'>CSS 3</p>
            </div>
            <div className='col-12'>
              <h2 className='mt-5 mb-2'>Database</h2>
              <p className='mb-0'>MySQL</p>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='mb-2 mt-5 mt-lg-0'>Research Methods</h2>
              <div className='row align-items-center'>
                <div className='row align-items-center'>
                  <div className='col-2'>
                    <div className='p-1'>
                      <div style={{ border: '1px solid gray' }}>
                        <Communicate color='gray' />
                      </div>
                    </div>
                  </div>
                  <div className='col-10'>Internal Interviews</div>
                  <div className='col-2'>
                    <Everything />
                  </div>
                  <div className='col-10'>
                    <p>Platform Testing</p>
                  </div>
                  <div className='col-2'>
                    <Strategy color='gray' />
                  </div>
                  <div className='col-10'>
                    <p>Strategy Sessions</p>
                  </div>
                  <div className='col-2'>
                    <MarketResearch color='gray' />
                  </div>
                  <div className='col-10'>
                    <p>Market Research</p>
                  </div>
                  <div className='col-2'>
                    <Personas color='gray' />
                  </div>
                  <div className='col-10'>
                    <p>Persona Research</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const QuickTour = () => (
  <section className='bg-light py-6'>
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-12'>
          <h2 className='text-center mb-2'>Take a quick tour</h2>
          <p className='text-center'>Take a quick look at the brand guide website here or view our live site.</p>
        </div>
        <div className='col-12 text-center my-5'>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe
              className='embed-responsive-item'
              src='https://www.youtube.com/embed/2PHTJVX5lT4'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className='col-12 text-center'>
        <a className='btn btn-primary' href='https://brand.729solutions.com' target='_blank' rel='noreferrer'>
          Visit the 729 Brand Guide
        </a>
      </div>
    </div>
  </section>
)

const BrandGuideChip = () => (
  <section className='my-6 bg-gray-700'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <StaticImage src='../../images/portfolio/729/brand-chip.jpg' alt='Brand Guide Flow' />
        </div>
      </div>
    </div>
  </section>
)

const FocusAreasAndNeeds = () => (
  <section className='my-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <h2>Key Focus Areas</h2>
          <p>How can we make the brand guide easy to use for all teams – tech savvy or not?</p>
          <p>How should we structure all tools and assets?</p>
          <p>What platform should we use that is easy to update and maintain?</p>
          <p>How can we unify brand identity across all platforms and marketing channels?</p>
        </div>
        <div className='col-12 col-lg-6 mt-5 mt-lg-0'>
          <div className='row'>
            <div className='col-12'>
              <h2>Core Client Needs</h2>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
              <p className='mb-0'>
                <b>Cohesive Branding</b>
              </p>
              <p>The brand guide must define our brand and provide a library of assets to ensure it is used cohesively.</p>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
              <p className='mb-0'>
                <b>Resource for all</b>
              </p>
              <p>The brand guide must benefit and help save time for all our internal teams as well as external partners.</p>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
              <p className='mb-0'>
                <b>Central Location</b>
              </p>
              <p>All tools and assets must be stored and updated in one central location.</p>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
              <p className='mb-0'>
                <b>Easy to Maintain and Grow</b>
              </p>
              <p>The platform must be easy to update, maintain, and must have the capability to grow.</p>
            </div>
            <div className='col-12 col-lg-6 mb-4'>
              <p className='mb-0'>
                <b>Easy to use</b>
              </p>
              <p>Our teams and external vendors should be able to use the brand guide and assets with little to no instruction.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const ShowCase = () => (
  <section className='bg-light py-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Showcase</h2>
        </div>
      </div>
    </div>
    <ScrollContainer vertical={false} className='scroll-carousel py-6'>
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-1.jpg'
        alt='Portfolio Slide 1'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-2.jpg'
        alt='Portfolio Slide 2'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-3.jpg'
        alt='Portfolio Slide 3'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-4.jpg'
        alt='Portfolio Slide 4'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-5.jpg'
        alt='Portfolio Slide 5'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-6.jpg'
        alt='Portfolio Slide 6'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-7.jpg'
        alt='Portfolio Slide 7'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
      <StaticImage
        layout='fixed'
        height='650'
        src='../../images/portfolio/729/slide-8.jpg'
        alt='Portfolio Slide 8'
        className='scroll-carousel-item mr-5'
        imgClassName='scroll-carousel-img'
      />
    </ScrollContainer>
  </section>
)

const Learnings = () => (
  <section className='my-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Learnings</h2>
        </div>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <p>
                This was an interesting challenge that included all teams within our company, from sales to development. It was enlightening to find out how
                different teams use the brand in different ways, and how this new resource would become a part of their workflow.
              </p>
              <p>
                Our design team rose to the challenge and created an easy to use website that leaves little to no questions for the end-user. We used various
                applications to accommodate specific teams and their workflows. Google Slides and Drive store our proposals and presentations as it is the Sales
                Team’s app of choice. Adobe XD drives our UI/UX templates and streamlines the process between design and dev teams. Assets are easy to find and
                can be downloaded in various file formats to accommodate each user, depending on their needs.
              </p>
              <p>
                From a development perspective, our approach was to create a platform that makes it easy for non-developers to maintain in the future. We build
                the brand guide using the Divi WordPress theme which makes updates extremely easy.
              </p>
            </div>
            <div className='col-12 col-lg-6'>
              <p>
                The fact that 729 offers a highly-skilled design and development team and a wide variety of software development skills made a huge difference
                in effective problem-solving for this project.
              </p>
              <p>
                Overall, this project allowed 729 Solutions to grow as a provider and showcase that we know how to deploy the best methodologies to solve
                complex challenges. This project specifically proved that we can offer a one-stop-shop, from research to design and design to development, all
                in fast turnaround times when the need arises, which truly delights our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const SevenTwentyNineQuote = () => (
  <section className='mt-6 mb-0 bg-dark pb-lg-6 pt-6'>
    <div className='container'>
      <div className='row align-items-center'>
        <div className='col-12 col-lg-6'>
          <Quote
            dark
            quote='The 729 Brand brings together Rob’s personality and my vision for how we present 729 as a company. The 729 Brand Guide tells it’s users how to execute that on a day to day basis.'
            name='Nikki Gagliardo'
            company='Creative Director, 729 Studios'
          />
        </div>
        <div className='col-12 col-lg-6'>
          <div className='p-6'>
            <StaticImage style={{ width: '100%' }} src='../../images/portfolio/729/logo.png' alt='729 Solutions Logo' />
          </div>
        </div>
      </div>
    </div>
  </section>
)

const ProjectForm = ({ location }) => (
  <section className='bg-light py-6 mt-0 mb-6'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <h2>Have a Project?</h2>
          <p className='pr-5'>We'd love to hear about it!</p>
          <small className='text-muted pr-5'>
            Email us at <a href='mailto:solicitations@729solutions.com'>solicitations@729solutions.com</a> for solicitations regarding partnerships, linkbacks,
            or subcontracting opportunities.
          </small>
        </div>
        <div className='col-12 col-lg-6'>
          <ContactForm showHowYouFindUs={false} buttonText='Submit' location={location} />
        </div>
      </div>
    </div>
  </section>
)

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo pathName={location.pathname} title='729 Brand Guide Website' />
      <PortfolioHeader />
      <MockupImages />
      <ProjectGoals />
      <HowWeDidIt />
      <QuickTour />
      <BrandGuideChip />
      <FocusAreasAndNeeds />
      <ShowCase />
      <Learnings />
      <SevenTwentyNineQuote />
      <ProjectForm location={location} />
      <PortfolioFeed />
    </Layout>
  )
}

export default Index
